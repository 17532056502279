@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

*::selection {
  color: #ffffff !important;
  background: #165ae3 !important;
}

body {
  width: 100vw;
  height: 100vh;
}

#root,
#root > div {
  width: 100%;
  height: 100%;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
