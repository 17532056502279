.layout-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 60px 1fr 230px 30px;
  overflow: hidden;
}

.layout-grid > *:nth-child(1) {
  grid-area: 1 / 1 / 2 / 3;
  border-bottom: 1px solid #d0d5dd;
}

.layout-grid > *:nth-child(2) {
  grid-area: 2 / 1 / 3 / 2;
}

.layout-grid > *:nth-child(3) {
  grid-area: 3 / 1 / 4 / 2;
  background: #165ae3;
}

.layout-grid > *:nth-child(4) {
  grid-area: 4 / 1 / 5 / 2;
}

.layout-grid > *:nth-child(5) {
  grid-area: 2 / 2 / 5 / 3;
  border-left: 1px solid #d0d5dd;
}

.broker-layout-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 60px 1fr;
  overflow: hidden;
}

.broker-layout-grid > *:nth-child(1) {
  grid-area: 1 / 1 / 2 / 3;
  border-bottom: 1px solid #d0d5dd;
}

.broker-layout-grid > *:nth-child(2) {
  grid-area: 2 / 1 / 3 / 2;
}

.broker-layout-grid > *:nth-child(3) {
  grid-area: 2 / 2 / 3 / 3;
}
