.text-editor {
  width: 100%;
  height: 100%;
}

.text-editor .tox-tinymce {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;
}

.text-editor .ql-toolbar {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;
}

.text-editor .ql-container {
  border: none;
  border-radius: 0;
}

.ql-container .ql-editor {
  font-family: "Inter", Arial, sans-serif;
  font-size: 14px;
  max-height: 250px;
  border-bottom: 1px solid #e0e0e0;
}

.ql-container .ql-editor p {
  margin: auto;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.ql-container .ql-toolbar {
  padding-top: 0;
  padding-bottom: 0;
}
